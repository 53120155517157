import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [2];

export const dictionary = {
		"/": [~10],
		"/(app)/customers": [~12,[2],[3]],
		"/(app)/customers/new": [14,[2],[3]],
		"/(app)/customers/[id]": [~13,[2],[3]],
		"/(app)/dashboard": [~15,[2],[3]],
		"/(app)/inventory": [16,[2],[3]],
		"/(app)/inventory/input": [~17,[2],[3,4]],
		"/(app)/inventory/input/many": [18,[2],[3,4]],
		"/(app)/inventory/output": [~19,[2],[3,5]],
		"/(app)/inventory/reports": [~20,[2],[3]],
		"/(app)/inventory/reports/[slug]": [~21,[2],[3]],
		"/(auth)/login": [37,[6]],
		"/(app)/orders": [~22,[2],[3]],
		"/(auth)/orders/confirm/[id]": [~38,[6,7],[,,8]],
		"/(app)/orders/new": [24,[2],[3]],
		"/(app)/orders/[id]": [~23,[2],[3]],
		"/(app)/production": [~25,[2],[3]],
		"/(app)/registration/brands": [~26,[2],[3]],
		"/(app)/registration/categories": [~27,[2],[3]],
		"/(app)/registration/kits": [~28,[2],[3]],
		"/(app)/registration/kits/new": [29,[2],[3]],
		"/(app)/registration/products": [~30,[2],[3]],
		"/(app)/registration/products/new": [~32,[2],[3]],
		"/(app)/registration/products/[id]": [~31,[2],[3]],
		"/(app)/registration/suppliers": [~33,[2],[3]],
		"/(app)/registration/units": [~34,[2],[3]],
		"/(app)/settings": [~35,[2],[3]],
		"/(auth)/signup": [39,[6]],
		"/(auth)/signup/verify": [~40,[6],[,9]],
		"/(app)/test": [36,[2],[3]],
		"/(app)/[...path]": [~11,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';